/*******************************************************************************
 ** Core styling */
body, iframe, fieldset, form, label, legend, input, select, textarea, table, caption, tbody, tfoot, thead, tr, th, td {
    font-family: arial !important;
}

p { margin-top: 0; }
p:last-child,
p.last-child { margin-bottom: 0.2em; }
h1 { font-size: 18px; margin: 0 0 0.67em; }
h3 { font-size: 14px; margin: 0 0 0.67em; }
hr { color: #DDD; }
table { border-spacing: 2px; }
table#birthday_table { border-spacing: 0; }
table#post-icons { border-spacing: 5px; }
select,
input[type=text],
input[type=password],
textarea { background: #fff; color:#444; font-size: 12px; border: 1px solid #abadb3!important; margin: 2px 2px 2px 0; padding: 0; border-radius: 3px; box-sizing: content-box !important; }
select,
input[type=text],
input[type=password] { height: 18px; }
input[type=text],
input[type=password],
textarea { color: #000; padding: 1px; vertical-align: middle; }
textarea { vertical-align: top; }
select option { background: #fff; text-align: left; }
select option selected { background: #fff; text-align: left; }
.formcontrols .blockrow input.numeric,
.formcontrols .blockrow select.numeric { text-align: left; }
/*******************************************************************************
 ** Rounded corners */

/* Reset vb4 core */
table.tborder .tcat { border-radius: 0; font-family: arial !important; }

/* Tables with only one tbody */
table.tborder > :only-child > tr:only-child > :first-child { border-radius: 5px 0 0 5px; }
table.tborder > :only-child > tr:only-child > :last-child  { border-radius: 0 5px 5px 0; }
table.tborder > :only-child > tr:only-child > :only-child  { border-radius: 5px; }

table.tborder > :first-child > tr:first-child > :only-child {
  height: 30px;
  font-size: 16px;
  padding-left: 10px !important;
  background-color: #7B63DB;
  color: #FFFFFF;
}
/* Tborder divs */
div.tborder > :first-child { border-radius: 5px 5px 0 0; }
div.tborder > :last-child { border-radius: 0 0 5px 5px; }
div.tborder > :only-child { border-radius: 5px; }
div.tborder { border-radius: 5px; }

.input-submit,
.input-reset,
.vBulletin_editor,
.flashmessage,
.buddylist_box,
.album_info_box,
.buy_more_credits,
a.link-button,
#neweventbutton,
#ajaxbox .button
{
  border-radius: 5px;
}

.rounded5500,
.content_block > h4,
.block_tab,
#group_members_tab,
.subscription_banner .tcat,
table.tborder > :only-child > tr:only-child > td.message_history,
.bar {
  border-radius: 5px 5px 0 0;
}

.rounded0055,
.content_block > .block_content > :last-child,
.block_footer,
.block_content,
#threadpms .tborder .alt2 {
  border-radius: 0 0 5px 5px;
}

#profile_tabs ul.tab_list li.overflow_tab {
  border-radius: 0 5px 0 0;
}

.subs_bit_top {
  border-radius: 10px 10px 0 0;
}

.subs_bit_bottom {
  border-radius: 0 0 10px 10px;
}

.subs_bit_bottom_right {
  border-radius: 0 0 10px 0;
}

/*  collapse icons for core stylling */
.blockhead h2 { font-size: 12px; margin: 0; }
.blockhead a.collapse { top: 0; }

/* vbform */
.vbform .blockbody { padding-top: 1em; }
.vbform .blockhead { line-height: 17px; }
.vbform .blockfoot .group { text-align: center; }

/* popups */
.popupbody { border: 1px solid #666666; }
.popupbody li > a, .popupbody li > label { font: 12px arial; }

/*******************************************************************************
 ** MISCELLANEOUS STYLES THAT NEED TO BE CLEANED UP. Srsly. */

.clear:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; zoom: 1; }

.actionbuttons .group .button { padding: 3px 6px; font-size: 12px; }

/* 12px */
.heading{ font-size: 12px; }
.heading-bold{ font-size: 12px; font-weight: bold; }

.white-text{ color: #ffffff; }
.grey-text{ color: #666666; }
.italic-text{ font-style: italic; }

.vbmenu_control { padding: 2px; font-weight: bold; font-size: 12px; white-space: nowrap; }

a:hover { text-decoration: underline; }
a img { border: 0; }

fieldset { border: 1px solid #CCC; padding: 5px; border-radius: 5px; }
.fieldset { font-size: 12px; margin-bottom: 0.5em; padding: 7px; }
.fieldset tr { padding: 2px; }
.fieldset, .fieldset td, .fieldset p, .fieldset li  { font-size: 12px; }
.fieldset li { white-space:nowrap; }
.fieldset:last-child,
.fieldset.last-child { margin-bottom: 0; }
.panel { padding: 1em; }
legend { color: #444; padding-left: 2px; }

.pagenav .alt1,
.pagenav .alt2 { font-size: 12px; }
.pagenav .smallfont { font-size: 12px; }

.inlinemod { background: #FFFFE3 !important; }
#imod:hover, #imod_menu td.vbmenu_option:hover { cursor: hand !important; cursor: pointer !important; text-decoration: underline; }

.navbar { font-family: arial; }

option.d1, option.d2, option.d3 { text-indent: 0 !important; }

.sort_arrow { text-decoration: none; }
.sort_arrow img { margin-left: 5px; }

#moderator_notifications { margin: 5px 0 0; }
#moderator_notifications td { padding: 0 0 0 1.5em; }

form .panelsurround { border: 1px solid #CCC; border-top: 0; }

.forumbit_titlecolumn, .forumbit_title { width: 100%; }
.forumbit_titlecolumn { padding-left: 2em; }

.panel { background: transparent; border: 0; }
.printfooter { display: none; }

.tcat { white-space: nowrap; font-weight: bold; padding: 6px; }
.tcat a,
.tcat a:link,
.tcat a:visited { color: #FFF; }
div.tcat { box-sizing: border-box; }
.selected.tcat a { font-weight: normal !important;}

.smallfont-bold { font-size: 11px; font-weight: bold; }

.recaptchatable { background: #FFF; }

.inlinelist li { float: left; list-style: none; margin: 0 11px 0 0; }
.inlinelist { width: 700px; padding: 0; margin: 6px; }
#primary_content #widget_moderator-tools .inlinelist { width: auto; margin: 0; }
.plainblocklist li { list-style: none; }

#member-tabs ul.ui-tabs-nav li { padding: 0.5em 1em; margin-left: 5px; border: 1px solid #DDD; }
#member-tabs ul.ui-tabs-nav li.ui-tabs-selected a { color: #FFF; }
#member-tabs ul.ui-tabs-nav { overflow: hidden; white-space: nowrap; }

.errortext { color: #F33; font-weight: bold; }
.option-tree-cell select { display: block; }

#search_results .highlight, #widget_related_content .highlight { color: #444; }

.table-columns { border-spacing: 0; width: 100%; margin-bottom: 1em; }
.table-columns > tr > td,
.table-columns > tbody > tr > td { vertical-align: top; padding: 0 0 0 12px; }
.table-columns > tr > td:first-child,
.table-columns > tbody > tr > td:first-child { vertical-align: top; padding: 0; }

.vbmenu_popup { background: #FFF; }

.tab_header { display: none !important; }
.tab_list li { text-align: center; border: 1px solid #DDD !important; border-bottom: 0 !important; }
.tab_list li.tcat { height: auto !important; }

.block_content, h4.block_title { border: 1px solid #DDD !important; }
.bordered-image { border: 1px solid #ddd; background: #fff; padding: 3px; }

.threadcontrol { padding-top: 0; vertical-align: top; }
.threadcontrol.first_unread { padding: 3px 0 0 10px; }
.navbar_button{ padding: 3px 6px 2px; display: inline;}

.description { padding-left: 10px; }

#forumdesc { margin-bottom: 0.5em; }
#forumdesc.forumdesc_forumdisplay { margin-bottom: 0; }
#forumdesc a { vertical-align: top; }
.view_groups { float: right; margin-bottom: 5px; clear: both }
#forumdesc img.rss_icon { padding-right: 5px; }
.forum-wrapper-header { float:left; line-height: 22px; margin: 0 0; }
.forum-wrapper-header img { float: left; margin: 0 5px 0 0; }
.forum-wrapper-header p { padding-top: 3px; }

#profileform table.tborder tr td { font-size: 11px; }
#profileform table.tborder tr td label { font-size: 12px; }

.navbar_button input { color: #fff; font-size: 12px; font-weight: bold; }

/* textarea and input box font-sizes */
#vB_Editor_001_textarea, textarea,
#pmrecips_txt, .bginput, #wiki_content input#wpSummary,
.mw-textarea-protected, input[type=text] { font-size: 12px; font-family: arial; padding: 1px; background: #fff !important; }

.input-submit, .input-button, .input-reset, a.link-button {
    font-size: 12px;
    font-weight: 900;
    font-family: arial !important;
    padding: 1px 6px 2px;
    margin: 2px;
    color: #fff;
    cursor: pointer;
    text-decoration: none !important;
    white-space: nowrap;
    border: 0 !important;
    display: inline-block;

    /* IE 7 hack because it doesn't support inline-block */
    *display: block;
}

.link-button-main-color {
  color: #7B63DB !important;
}

/******************************************************************************
/* CKE Editor  */
#cke_vB_Editor_001_editor { width: 550px; }
body .cke_button a.cke_button_table,
body .cke_button a.cke_button_vbTableProperties,
body .cke_button a.cke_button_tableDelete,
body .cke_button a.cke_button_vbRowInsertBefore,
body .cke_button a.cke_button_vbRowInsertAfter,
body .cke_button a.cke_button_vbRowDelete,
body .cke_button a.cke_button_vbColumnInsertBefore,
body .cke_button a.cke_button_vbColumnInsertAfter,
body .cke_button a.cke_button_vbColumnDelete,
#cke_46 .cke_separator, #cke_39 .cke_separator, #cke_45 .cke_separator, #cke_43 .cke_separator, #cke_42 .cke_separator { display: none !important; }
.cke_skin_kama span:not(.cke_browser_ie) .cke_button_strike .cke_icon { background-image: url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/editor/strikethrough.gif); background-position: 0 0; }


/***************************************************************************/

/******************************************************************************
/* Various button sprites */
.sprite_button, .login { height: 20px; overflow: hidden; text-indent: -9999px !important; font-size:0; line-height: 0; }
input.sprite_button.reply { width: 91px; background-position: 0 0; }
input.sprite_button.thread { width: 128px; background-position: 0 -30px !important; }
input.sprite_button#go { width: 29px; background-position: 0 -120px !important; }
input.sprite_button#inlinego { width: 54px; background-position: 0 -240px; text-indent: 0 !important; }
input.sprite_button#show_threads { width: 96px; background-position: 0 -60px !important; }
input.sprite_button#qr_preview { width: 89px; background-position: 0 -90px !important; }
input.sprite_button#qr_submit { width: 111px; background-position: 0 -180px !important; }
input.sprite_button#preview { width: 90px; background-position: 0 -150px !important; }
input.sprite_button#manage_attachments_button { width: 136px; background-position: 0 -210px !important; }

input.login#complete_reg { width: 143px; background-position: 0 -120px; }
input.login#clear { width: 42px; background-position: 0 -90px; }
input.login#login { width: 48px; background-position: 0 0; }
input.login#reset_fields { width: 83px; background-position: 0 -60px; }
input.login#request_user_pw { width: 220px; background-position: 0 -30px; }

.innerbox input.button:hover { background: transparent!important; }
.innerbox .button:hover { color: #424242 !important; background: none !important; }
/***************************************************************************/

#page_index .no_box { background: none !important; border: none; }

/*******************************************************************************
 ** vBulletin JavaScript elements */
.vbmenu_control a,
.vbmenu_control a:link,
.vbmenu_control a:visited,
.vbmenu_control_alink,
.vbmenu_control_avisited, .vbmenu_separator { color: #FFF; }
.vbmenu_popup { border: 1px solid #666; }
.vBulletin_editor { background: none repeat scroll 0 0 #E1E1E2; padding: 6px; border: 2px groove; }

/*******************************************************************************
 ** 90% of cells and borders */
table.tborder > thead > tr > td,
table.tborder > thead > tr > th,
table.tborder > tbody > tr > td,
table.tborder > tbody > tr > th,
table.tborder > tfoot > tr > td,
table.tborder > tfoot > tr > th,
div.tborder > *,
.alt1, .alt2,
.vbmenu_option { background: #FFFFFF; padding: 6px !important; }
.vbmenu_option { background: #FFF; }
ul.tab_popup li.vbmenu_option:hover, .vbmenu_popup .vbmenu_option:hover, .vbmenu_hilite { background: #ccc; padding: 6px !important; }
.thead_light { background: #f7f7f7 url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/base/thead-background-light.gif) repeat-x top !important; color: #444; }
.tfoot, .blockbody, .blockrow { background: #f6f6f6 !important; color: #444; font-size: 11px; }
.blocksubhead { background: #666 !important; border: none !important; }

#page_subscription .vbmenu_popup .vbmenu_option:hover,
#page_subscription .vbmenu_hilite,
#page_subscription ul.tab_popup li.vbmenu_option:hover {
  background: transparent !important;
}
table.tborder > thead > tr > :last-child,
table.tborder > tbody > tr > :last-child,
table.tborder > tfoot > tr > :last-child {
    border-right: 1px solid #DDD;
}

/* had to separate these two lines (above and this) to make it work on some pages in IE8 */
table.tborder > thead > tr > .last-child,
table.tborder > tbody > tr > .last-child,
table.tborder > tfoot > tr > .last-child {
    border-right: 1px solid #DDD;
}

div.tborder > * { border: 1px solid #DDD; border-top: none; }

.profile_warning_bit td { border-bottom: 1px solid #ddd !important; }

/* Adjusting forumbar and secondary bar height to 26px */
.tborder .tcat { padding: 5px 5px 4px !important; line-height: 17px; }

.chatbox .tcat { padding: 4px 5px 1px !important; }
.tcat input[type=checkbox] { margin: 0 3px; }

/*******************************************************************************
 ** Signup page */
#signuptoday { border: 1px solid #DDDDDD; padding: 1px 7px; margin-bottom: 20px; }
#signuptoday p { padding: 10px; margin: 0; }
#signuptoday p a { font-weight: bold; }
#signuptoday .signup-bg { background-repeat: repeat-x; height: 176px; }
#signuptoday .signup-bg-left { background-repeat: no-repeat; float: left; height: 176px; width: 12px; }
#signuptoday .signup-bg-right { background-repeat:no-repeat; float: right; height: 176px; width: 191px; }
#signuptoday .signup-bg div a { color: #ED7E13; font-weight: bold; }
#signuptoday .signup-bg img { margin-top: 20px; }
#signuptoday .signup-bg p { padding: 10px; color: #000; font-size: 12px; line-height: 20px; }
#signuptoday .join { float: right; margin-top: -5px; padding:10px; }
#signup_table td { padding: 5px; }

/*******************************************************************************
** Search */
.search input[type=text] { padding: 0; }
.search table td { vertical-align: middle !important; padding: 0 0 0 0.3em !important; }
.search .smallfont { font-size: 10px !important; line-height: 12px; }
#searchform .thead { display: block; width: auto; }
#searchform select { padding: 1px; }
#searchform input[type=checkbox] { margin: 2px; }

/*******************************************************************************
 ** Posts */
#posts > div { margin-bottom: 1em; }
#posts table { display: table; }
.postbit, .postbitlegacy { border: none !important; }
.postbit img, #collapseobj_threadreview img { max-width: 100%; max-height: 700px; }
.postbit.userprof img { max-width:400px }
.bigusername:hover { text-decoration : none; border-bottom: 1px solid; }
.post_title { margin-bottom: 10px; }
.post_title img { vertical-align: bottom; margin-right: 0; }
.vert_top {vertical-align: top;}
.wysiwyg_block .formcontrols .postcontrols { padding: 0 !important; margin-bottom: 10px; }
.wysiwyg_block .formcontrols .postcontrols ul li label { display: inline; }
.formcontrols .blockrow { padding: 5px; }
.blockfoot .actionbuttons { width: 250px; float: right; }
.floatcontainer:after, .formcontrols .blockrow:after, dl.stats:after { padding-bottom: 15px; }
.postbody fieldset { background: transparent!important; }

/* IE8 hack, posting full size images break the postbit #21439 */
.postbody blockquote div.size_fullsize {
  width: 700px !important;
  -ms-overflow-x: scroll;
}

/*******************************************************************************
 ** All Smilies */
.editor_textbox_container.smilie { border: 1px solid #D3D3D3; border-radius: 5px 5px 5px 5px; }

.editor_smiliebox { width: 83px !important; margin-right: 7px; }
.editor_smiliebox fieldset { margin-top: 5px; padding: 0; }
.editor_smiliebox ul.floatcontainer:after { padding-bottom: 0 !important; }
.editor_smiliebox ul.smiliebox { height: auto !important; margin-bottom: 15px; }
.editor_smiliebox ul.smiliebox li { width: 21px; height: auto !important; margin: 3px !important; }
.editor_smiliebox ul.smiliebox li .table { width: 21px; border-spacing: 2px; }
.editor_smiliebox ul.smiliebox li img { max-width: 19px; }
.editor_smiliebox fieldset .moresmilies { padding-bottom:4px; }

/* Smilies popup */
#smilies .blockhead p.description { padding: 0; margin-left: 20px }
#smilies .table .tablecell { background: #F6F6F6; }
#smilies ul.smilielist li div { border-color: #DDDDDD!important; }
#smilies h3.blocksubhead { margin: 0; background: #E8E8E8!important; color: #444444; font-size: 12px; }

/*******************************************************************************
 ** Quick Reply */
.quick_reply .tcat { padding: 5px 5px 4px !important; }
.quick_reply .tcat a { float: right; }
.quick_reply .panelsurround { border-right:none; vertical-align:top }
.quick_reply .panel { padding: 0; }
.quickreply_buttons { float: right; }
.quickreply_buttons input.button { float:right; margin:0 }

.ie9 #quickreply_title {
    background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/svg/gradient.0696c9.006da8.svgz") repeat-x;
    background-size: 100% 100%;
    filter: none;
}

#quickreply_title a.collapse { top: 0; float: right; }
#quick_reply .wysiwyg_block #cke_contents_vB_Editor_QR_editor { min-width: 200px !important; }
#quick_reply .wysiwyg_block ul.checkradio li { display:inline; margin-right:15px }
.editor_textbox_container { margin: 0 0 10px; }
/* #page_showthread #qr_submit { width: 125px;  max-width: none; } /* to fix an IE7 issue */
/* #page_showthread #qr_preview { width: 90px; max-width: none; } /* to fix an IE7 issue */
#page_showthread .texteditor.quickedit { border-top: 1px solid #C4C4C4; }

#quick_reply #qr_error_tbody .blockbody { border-bottom: none; padding: 0; }
#quick_reply #qr_error_tbody .thead { display: block; width: auto; font-size: 12px; font-weight: normal; margin-bottom: 0; padding: 6px; }
#quick_reply #qr_error_tbody #qr_error_td { border: none; margin: 0 0 0 60px; padding: 15px 0; text-align: left; }
#quick_reply #qr_error_tbody #qr_error_td ol { padding-left: 30px; }
#quick_reply #qr_error_tbody #qr_error_td li { margin-bottom: 6px; font-size: 12px; }
#quick_reply #qr_error_tbody .group { border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; text-align: center; padding: 6px 0; }
#quick_reply #qr_error_tbody .group a { background: none; border: none; font-weight: normal; }

#page_showthread #qr_error_td li { list-style-type: decimal; }

#page_showthread .texteditor.quickedit .textbox { height: 18px; }
#page_showthread #quickedit_delete .blockrow { border-top: 1px solid #ddd; }
#page_showthread #quickedit_delete .blockrow:after, #page_showthread #quickedit_delete .floatcontainer:after { padding: 0; }
#page_showthread #quickedit_delete li label { width: auto; }
#page_showthread #quickedit_delete .singledescription { color: #444; }
#page_showthread .texteditor.quickedit .editor_textbox { margin:0px }
#page_showthread .texteditor.quickedit span.cke_skin_kama { padding:0px; border:none !important }

#quick_reply .submit-button
, #quick_reply .advanced-button
{ float:right; cursor:pointer; font-family:arial !important; font-size:12px; font-weight:bold }
/*
#cke_vB_Editor_QR_editor { height: 240px; }
#cke_contents_vB_Editor_QR_editor { height: auto !important; }
*/

/*******************************************************************************
 ** jQuery UI */
.ui-sortable-placeholder { border: 1px dashed #CCC; height: 100px; margin-bottom: 10px; }

.ui-tabs-hide { display: none !important; }

.ui-widget-overlay { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.ui-widget-overlay { background: #333 url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/base/jqueryui/ui-bg_flat_0_333333_40x100.png) 50% 50% repeat-x; opacity: .70;filter:Alpha(Opacity=70); }
body > div.ui-dialog { border: 1px solid #333; background: #ffffff url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/base/jqueryui/ui-bg_flat_75_ffffff_40x100.png) 50% 50% repeat-x; color: #222222; }
.ui-dialog .ui-widget-header { padding: 0.7em; font-weight: bold; font-size: 10pt; }
.ui-dialog .ui-dialog-content { border: 0; padding: 1em; background: none; overflow: auto; zoom: 1; }
.ui-dialog .ui-dialog-buttonpane { text-align: right; border-width: 1px 0 0 0; background-image: none; padding: 0 1em 1em; }
.ui-dialog .ui-dialog-title { float: left; }
.ui-dialog .ui-dialog-titlebar {overflow: hidden;}
.ui-dialog-titlebar-close { float: right; background-position: -40px 0 !important; width: 16px; height: 17px; }
.ui-icon { display: block; text-indent: -99999px; overflow: hidden; background-repeat: no-repeat; }

/*******************************************************************************
 ** Widgets */
a.collapse { float: left; position: relative; right: 3px; top: 0;}

table.tborder tr td.widget_edit { padding: 4px 1px !important; -moz-border-radius-topleft: 0; -moz-border-radius-topright: 0; }
.widget_buttons { height: 20px; line-height: 0.5em; float: right; width: 1px; }
.widget_options { float: right; width: 32px; }
#primary_content .widget-content-column-home-primary,
#secondary_content .widget-content-column-home-secondary,
#gutter_content .widget .tcat, table.lightwidget .tcat { color: #000; }
table.lightwidget > thead > tr > td { border-top: 1px solid #DDD !important; border-bottom: 1px solid #DDD !important; }
table.lightwidget > thead > tr > td:first-child, table.arcadelight td.tcat:first-child { border-left: 1px solid #DDD !important; }
#gutter_content .widget > thead > tr > td:last-child, table.lightwidget > thead > tr > td:last-child, table.arcadelight td.tcat:last-child { border-right: 1px solid #DDD !important; }
#gutter_content .widget > thead > tr > td.last-child, table.lightwidget > thead > tr > td.last-child, table.arcadelight td.tcat.last-child { border-right: 1px solid #DDD !important; }
.widget-content { padding: 6px; }

.widget-head-row { background: #E8E8E8; border-bottom: 1px solid #DDD; }

/* moderator tools */
.widget-subcontent .smallfont {display: block;}
#widget_moderator-tools_body ul { margin: 0; padding: 0; }
#widget_moderator-tools .widget-content-column-home-primary li { border-top: 1px solid #ddd; padding: 8px 10px; }
#widget_moderator-tools .widget-content-column-home-primary li:first-child { border-top: none; }
#widget_moderator-tools .widget-content-column-home-primary li li { border: none; display: inline-block; white-space: nowrap; padding: 3px 20px 0 10px; }

.thead.thead-alt { padding: 3px 5px !important; }


.widget-content { padding: 0; }
.widget-content .tborder tr.last-child td { border-bottom: 0 !important; }
.widget-content .tborder tr:last-child td { border-bottom: 0 !important; } /* had to separate these two lines (above and this) to make it work on some pages in IE8 */
.ui-tabs-panel > table.tborder > tbody > tr:first-child > .alt1 { border-top: 0; }

.ui-dialog-titlebar { color: #FFF; }

.clickable { cursor: pointer; }
#page_home .clickable{ color: #fff; }

#page_index .widget_column .tcat, #page_member .widget_column .tcat { line-height: 9px; }

/*reputation widgets*/
.posrating { color: #679A0D; }
.negrating { color: #A81839; }
.reputation_points { float: left; padding-top: 3px; color: #444444; font-size: 11px; text-align: left}
img.repimg { vertical-align: middle; margin-left: 0 !important; }
.inline_rep img { vertical-align: middle; }

/*clearing widget*/
#widget_clearing .widget-content .clearing-header,
#widget_clearing .widget-content .clearing-body { padding: 5px; }
#widget_clearing .widget-content #clearing-image { width:100%; height: 101px; }
#widget_clearing .widget-content h1 { color: #44A0D4; margin-bottom: 0.4em; }
#widget_clearing .widget-content p { padding: 0.5em 0 0.3em 0; margin: 0; font-size: 12px; }

/*******************************************************************************
 ** User Tab Drop  */
ul.tab_list li.thead:hover, ul.tab_list li.thead:hover *, ul.tab_popup li.vbmenu_option:hover, ul.tab_popup li.vbmenu_option:hover * {
background: #cccccc !important;
color: inherit !important;
cursor: hand !important; cursor: pointer !important;}

#forumtools_menu td.vbmenu_option_alink, #forumtools_menu td.vbmenu_hilite_alink { padding: 4px !important; }

/*******************************************************************************
 ** Search Results */
.search_events_image_col, .search_groups_image_col { width: 75px !important; }
.search_groups_image_col .bordered-image { width: 84px; height: 84px; padding: 4px; }
.search_groups_info_col { width: 100%; }
.search_groups_info_col h3 { margin: 0; }
.search_groups_info_col .inlinelist li { margin: 0 32px 0 0; }
#search_results_sort .selected { border: 1px solid #ccc; background: #f6f6f6; padding: 2px 4px !important; }
#search_results_sort li.selected a { color: #7B63DB !important; }
#search_results #inlinemodform input#checkall_all { margin-left: 5px; }

/*******************************************************************************
 ** Announcements */
.announ-ad-postby { white-space: nowrap; }
.announcement_ad { width: 20px;}

#page_announcement #cke_vB_Editor_001_editor { width: 517px; }

/*******************************************************************************
 ** Chat Box */
#mgc_cb_evo_table { margin-bottom: 1em; }
.mgc_cb_evo_block { margin-bottom: 1em; margin-top:1em!important; }
.mgc_cb_evo_block .blockhead { width: auto; }
.mgc_cb_evo_block .blocksubhead { display: none; }
.mgc_cb_evo_block .blockbody { border: none !important; }
.mgc_cb_evo_block .blockrow, .mgc_cb_evo_block .blockfooter { background: #f6f6f6 !important; border: 1px solid #ddd !important; border-top: 0 !important; padding: 10px; }
.mgc_cb_evo_block .blockrow { border-bottom: none !important; padding: 0; }
.mgc_cb_evo_block .blockrow.editor { border-bottom: 1px solid #ddd !important; }
.mgc_cb_evo_block .blockrow .tabs_container { padding: 10px 0 0 10px; }
.mgc_cb_evo_block .blockrow .chat_box_container { padding: 10px; }
.mgc_cb_evo_block .blockrow .tabs_container #channel_1_on,
.mgc_cb_evo_block .blockrow .tabs_container #channel_1_off { margin-bottom: -2px !important; }
#chats_container { border: 2px solid #999 !important; background: #FFF; padding: 10px; width: 97% !important; }
#chats_container .cb_vbmenu_popup, .yui-panel { background: #eee; padding: 10px; border: 1px solid #666; position: relative; }
#chats_container td span .inlineimg { vertical-align: middle; }
.container-close { position: absolute; top: 5px; right: 8px; }
.yui-button { margin-right: 5px; }
#primary_content > .yui-skin-sam { position: absolute; }
#chatpopupinput .hd { display: none; }
#mgc_cb_evo_form table { background: url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/base/shoutbox_bg.png) no-repeat scroll 0 0 transparent; width: 649px; height: 132px; margin: 15px 0 0 0; padding: 0 20px 0 0; }
#mgc_cb_evo_form table td:first-child { padding-left: 35px; }
#mgc_cb_evo_form table .editor_input_row { text-align: right; }
#mgc_cb_evo_form table .editor_input_row #chatinputAutocomplete { display: inline-block; }
#mgc_cb_evo_form table input { margin-right: 5px; }
#mgc_cb_evo_form table .input-submit { width: 55px; padding: 5px 0; box-sizing: content-box; }
#mgc_cb_evo_form table a { vertical-align: middle; }
#mgc_cb_evo_opened .tfoot, #mgc_cb_evo_opened .blockbody, #mgc_cb_evo_opened .blockrow { font-size: 12px !important; }
.mgc_archives { float: right; width: 100%; margin-right: 5px; font-size: 12px; }
#mgc_cb_evo_opened #chats_container #chats span.normalfont, #mgc_cb_evo_opened #chats_container #chats .normalfont strong { vertical-align: top; }
#chatbox_editor td.editor_input_row { text-align: right; padding-right: 10px; width: 90%; }
#page_mgc_cb_evo .pagination span { clear: none; display: inline; float: none; }

/*******************************************************************************
 ** Debugging */
.error { margin: 0.5em; padding: 1em; border: 1px solid #F99; background: #FCC; text-align: center; }

/*******************************************************************************
 ** Bookmarks (alternative for Explore TSR button) */
#page-columns .bookmarks { background: #ededed; margin: 0 0 16px; padding: 8px 10px; border-radius: 5px; }
#page-columns .bookmarks p { font-size: 11px; margin: 0 0 4px; }
#page-columns .bookmarks p a { font-size: 10px; }
#page-columns .bookmarks .bookmark_links a { font-size: 11px; display: inline-block; }
#page-columns .bookmarks .bookmark_links span { margin: 0 5px; }

/*******************************************************************************
 ** Helpful Answers */
.post-score-neg-highlight { color:#a81839 }
.post-score-pos-highlight { color:#679a0d }
.post_score_guest { font-weight: bold; color: #999; z-index: 100; }

/*******************************************************************************
 ** User Warnings */
#page_infraction .tborder .panelsurround { padding: 10px 0 !important; }
#page_infraction .tborder .panelsurround .panel { padding: 0 0 10px; }
#page_infraction .warnings { border-bottom: 1px solid #ccc; padding: 0 10px; }
#page_infraction .warnings .username { font-weight: bold; text-align: left; }
#page_infraction .warning_stats { float: right; }
#page_infraction .warning_stats td { padding: 0 10px; border-right: 1px solid #000; }
#page_infraction .warning_stats td:last-child,
#page_infraction .warning_stats td.last-child { border-right: none; }
#page_infraction .panel .infractions td { padding: 5px; border-bottom: 1px solid #ccc; }
#page_infraction .panel .warning_header { background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/base/thead_bg2.gif") repeat-x; height: 21px; font-weight: bold; text-align: left; margin-bottom: 0; padding:5px 10px; }

/*******************************************************************************
 ** Buddy list */
#page_profile dl.stats.totals dd,
#page_profile dl.stats.totals dt { color: #fff; }
#buddylist_change_form .blockbody, #buddylist_change_form .blockrow { background: #f6f6f6; }
#buddylist_change_form .floatcontainer label { font-weight: bold; }
#buddylist_change_form .show_avatars_ctrl { float: none; }
#buddylist_change_form .filtercontainer { background: #e8e8e8; border: 1px solid #c4c4c4; border-top: none; border-bottom: none; text-align: right; padding: 3px 5px; }
#buddylist_change_form .settings_form_border { border-bottom: none; }
#buddylist_change_form .actionbuttons { background: #f5f5ff; border: 1px solid #c4c4c4; border-top: none; border-bottom: none; }
#buddylist_change_form #submit_save { float: right; margin-top: 8px; }
#buddylist_add_form .blockbody { background: #f6f6f6; -moz-border-radius: 0 0 5px 5px; }
#buddylist_add_form .formcontrols .section { margin-bottom: 3px; }
#buddylist_add_form .formcontrols .section .blockrow { background: #fcfcfc; border: 1px solid #ddd; -moz-border-radius: 5px; padding: 10px; }
#buddylist_add_form #buddylist_add { width: 100%; max-width: 100%; margin-left: 0; margin-bottom: 20px; padding-bottom: 20px; border-bottom: 1px dashed silver; }
#buddylist_add_txt { width: 300px; }
#buddylist_add_form li.vbmenu_option a { font-size: 12px; }
#buddylist_add_form #buddylist_add_body { border: 1px solid #ccc; padding: 1px; }
#buddylist_add_form .singledescription { line-height: 25px; }


#buddylist_change_form .floatcontainer:after { padding: 0; margin: 0; }
.add_buddy_form {background-color: #fcfcfc; padding: 10px; border: 1px solid #ddd; font-size: 12px;}

h2#irc { margin-top: 25px; }
#incomingreqs_change_form h3 { background: #e8e8e8; color: #444; font-size: 12px; font-weight: normal; }
#incomingreqs_change_form .blockbody, #incomingreqs_change_form .blockrow { background: #f6f6f6; }
#incomingreqs_change_form .blockbody { border-radius: 0 0 5px 5px; }
#incomingreqs_change_form .check_all_ctrl { float: right; text-align: right; }
#incomingreqs_change_form .blockrow:first-child { padding: 0; }
#incomingreqs_change_form .blockrow:last-child { border-top: none; padding-left: 0; }
#incomingreqs_change_form .blockrow:last-child li { float: left; margin: 0; padding: 4px 10px 4px 4px; border: 1px solid silver; border-radius: 5px; }
#incomingreqs_change_form .blockrow:last-child li:last-child { margin-left: 10px; }
#incomingreqs_change_form .blockrow:last-child li label { width: auto; }
#incomingreqs_change_form .blockfoot { border-top: 1px solid #ddd; padding: 6px; text-align: right; }

/*******************************************************************************
 ** Sprites (bars & others) */
.sprite_widget_buttons                { width: 16px; height: 17px; display: inline-block; }
.sprite_widget_buttons_close          { background-position: 0 0 !important; font-size: 0; /* to fix IE issue */ }
.sprite_widget_buttons_close_light    { background-position: 0 -21px !important; font-size: 0; /* to fix IE issue */ }

.sprite_forum_buttons              { height: 19px; display: inline-block; text-decoration: none; cursor: pointer; }
.sprite_forum_buttons_assoc_groups { background-position: 0 0; width: 180px; height: 24px; }
.sprite_forum_buttons_search_forum { background-position: 0 -30px !important; width: 144px; }
.sprite_forum_buttons_forum_tools  { background-position: 0 -55px !important; width: 106px; }
.sprite_forum_buttons_newthread    { background-position: 0 -80px !important; width: 131px; }

.sprite_group_buttons        { height: 34px; display: inline-block; text-decoration: none; }
.sprite_group_buttons_deputy { background-position: 0 0; width: 196px; }
.sprite_group_buttons_leader { background-position: 0 -40px !important; width: 138px;  }
.sprite_group_buttons_member { background-position: 0 -80px !important; width: 97px;  }
.sprite_group_buttons_join   { background-position: 0 -120px !important;; width: 111px; height: 33px; cursor: pointer; }
.sprite_group_buttons_edit   { background-position: 0 -160px !important;; width: 111px; height: 33px; cursor: pointer; }

#page_profile .im input.bginput { float: left; /* mainly to make all browsers behave the same - for the below sprites */ }
.sprite_im       { background: url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/miscellaneous/im_sprite.png) no-repeat; width: 17px; height: 17px; display: inline-block; float: left; margin-right: 4px; }
.sprite_im_icq   { background-position: 0 0; }
.sprite_im_aim   { background-position: -25px 0 !important; }
.sprite_im_msn   { background-position: -50px 0 !important; }
.sprite_im_yahoo { background-position: -75px 0 !important; }
.sprite_im_skype { background-position: -100px 0 !important; }

.sprite_register_icons   { background: url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/regpromo/register_icons_sprite.png) no-repeat; float: left; width: 27px; height: 32px; display: inline-block;   margin: 0 10px 0 5px; }
.sprite_register_icons_1 { background-position: 0 0; }
.sprite_register_icons_2 { background-position: -35px 0; }
.sprite_register_icons_3 { background-position: -70px 0; }
.sprite_register_icons_4 { background-position: -105px 0; }
.sprite_register_icons_5 { background-position: -140px 0; }
.sprite_register_icons_6 { background-position: -175px 0; }
.sprite_register_icons_7 { background-position: -210px 0; }

.sprite_tournament          { width: 187px; height: 20px; display: inline-block; }
.sprite_tournament_create   { background-position: 0 0; }
.sprite_tournament_join     { background-position: 0 -30px !important; width: 153px; }
.sprite_tournament_finished { background-position: 0 -60px !important; width: 186px; }

.sprite_chat_buttons              { width: 103px; height: 26px; display: inline-block; }
.sprite_chat_buttons_backroom_off { background-position: 0 0; }
.sprite_chat_buttons_backroom_on  { background-position: 0 -35px !important; }
.sprite_chat_buttons_modbox_off   { background-position: -110px 0 !important; }
.sprite_chat_buttons_modbox_on    { background-position: -110px -35px !important; }

.sprite_step_number { width: 30px; height: 32px; position: absolute; top: 0; left: 0; }
.sprite_step_number_1 { background-position: 0 0; }
.sprite_step_number_2 { background-position: -40px 0 !important; }
.sprite_step_number_3 { background-position: -80px 0 !important; }

.sprite_flags {
  background: url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/flags/flags_sprite.png) no-repeat;
  width: 30px;
  display: inline-block;
  vertical-align: middle;
}

.sprite_flags.anarchist { background-position: 0 0; height: 18px; }
.sprite_flags.arsenal { background-position: 0 -28px; height: 37px; }
.sprite_flags.australia { background-position: 0 -75px; height: 15px; }
.sprite_flags.bangladesh { background-position: 0 -100px; height: 18px; }
.sprite_flags.canada { background-position: 0 -128px; height: 15px; }
.sprite_flags.central_african_republic { background-position: 0 -153px; height: 18px; }
.sprite_flags.china { background-position: 0 -181px; height: 20px; }
.sprite_flags.england { background-position: 0 -211px; height: 18px; }
.sprite_flags.european_union { background-position: 0 -239px; height: 18px; }
.sprite_flags.france { background-position: 0 -267px; height: 20px; }
.sprite_flags.germany { background-position: 0 -297px; height: 18px; }
.sprite_flags.hong_kong { background-position: 0 -325px; height: 20px; }
.sprite_flags.india { background-position: 0 -355px; height: 20px; }
.sprite_flags.ireland { background-position: 0 -385px; height: 15px; }
.sprite_flags.italy { background-position: 0 -410px; height: 20px; }
.sprite_flags.lithuania { background-position: 0 -440px; height: 20px; }
.sprite_flags.malaysia { background-position: 0 -470px; height: 15px; }
.sprite_flags.manchester_united { background-position: 0 -495px; height: 30px; }
.sprite_flags.netherlands { background-position: 0 -535px; height: 20px; }
.sprite_flags.nigeria { background-position: 0 -565px; height: 15px; }
.sprite_flags.northern_ireland { background-position: 0 -590px; height: 15px; }
.sprite_flags.pakistan { background-position: 0 -615px; height: 20px; }
.sprite_flags.poland { background-position: 0 -645px; height: 19px; }
.sprite_flags.scotland { background-position: 0 -674px; height: 18px; }
.sprite_flags.spain { background-position: 0 -702px; height: 20px; }
.sprite_flags.sweden { background-position: 0 -732px; height: 19px; }
.sprite_flags.united_kingdom { background-position: 0 -761px; height: 15px; }
.sprite_flags.united_nations { background-position: 0 -786px; height: 20px; }
.sprite_flags.united_states { background-position: 0 -816px; height: 16px; }
.sprite_flags.wales { background-position: 0 -842px; height: 18px; }

/*******************************************************************************
 ** facebook & twitter */
.social-connect {margin-top: 5px; }

/*******************************************************************************
 ** Customise TSR */
h2#customise-page { width: auto; }

/*******************************************************************************
 ** Birthday Logos */
td.birthday.main { padding-bottom: 5px; }
td.birthday.subscriber { padding-top: 12px; padding-left: 5px; }

/*******************************************************************************
 ** Private messages */
#newpost_options input { margin-bottom: 5px; }

#usercp_pmfilter div.smallfont input,
#usercp_pmfilter div.smallfont select,
#usercp_pmfilter div.smallfont a,
#usercp_pmfilter div.smallfont img { vertical-align: top !important; }
#usercp_pmfilter div.smallfont input { height: 16px; }
#usercp_pmfilter div.smallfont input[type=submit] { height: 20px; }
#usercp_pmfilter div.smallfont label { display: inline-block; margin-top: 5px; vertical-align: top; }
#usercp_pmfilter div.smallfont img { margin-top: 2px; }

/*******************************************************************************
 ** Polls */

/* New Poll */
#page_poll .misc_options input { margin-bottom: 5px; }
#page_poll .restricted_voters select { height: auto; }
#page_poll .group.multifield label { display: block; }
#page_poll .group.multifield .textbox { margin-bottom: 5px; }

/* Poll vote */
#page_showthread input.poll_option { margin-bottom: 6px; }
#page_showthread .fieldset.nobox { border: none; padding-left: 0; }

/* Poll Results */
#page_showthread .alt1 .italic,
#page_poll .alt1 .italic { font-style: italic; }

/*******************************************************************************
 ** User Groups */

#page_joinrequests .joinrequestsform table { width: 100%; border-spacing: 0; }
#page_joinrequests .joinrequestsform td { font-size: 12px; padding: 6px; }
#page_joinrequests .joinrequestsform .thead { background: #f5f5f5 url(//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/base/thead-background.gif) repeat-x bottom !important; }
#page_joinrequests .joinrequestsform .thead a { padding: 3px 9px; }
#page_joinrequests .joinrequestsform .group { text-align: right; }

/*******************************************************************************
 ** Social Groups */

/* Group members list */
#socialgroup_members h2 { padding: 5px 5px 4px; }
#socialgroup_members #view-friends { background: #f6f6f6; border: 1px solid #ddd; border-top: none; margin: 0; padding: 6px; border-radius: 0 0 5px 5px; }
#socialgroup_members #view-friends .friends_list { min-height: 0; padding: 6px; }
#socialgroup_members #view-friends li { width: 100%; margin-bottom: 5px; }
#socialgroup_members #view-friends li ul { padding: 6px; }
#socialgroup_members #view-friends li li { float: none; display: inline; }
#socialgroup_members #view-friends li .fixed_width_avatar { padding: 0; }
#socialgroup_members #view-friends li img { background: none; }

/* Manage members */
#memberlist_change_form { width: auto; max-width: none; }
#memberlist_change_form h2 span { float: right; }
#memberlist_change_form h2 a { color: #fff; font-size: 11px; font-weight: normal; }
#memberlist_change_form h2 a:hover { text-decoration: underline; }
#memberlist_change_form .blockbody { padding: 16px; border-radius: 0 0 5px 5px; }
#memberlist_change_form fieldset { width: 640px; border: 1px solid #ccc; margin: 0 auto; }
#memberlist_change_form fieldset legend { font-size: 12px; }
#memberlist_change_form .formcontrols .blockrow { border-top: none; padding-bottom: 0; }
#memberlist_change_form h3 { font-size: 12px; margin-bottom: 0; }
#memberlist_change_form p { font-size: 12px; }
#memberlist_change_form #memberlist { margin: 6px 0; }
#memberlist_change_form #memberlist li { padding: 2px 0 0 6px; }
#memberlist_change_form #memberlist a { font-weight: normal; }
#memberlist_change_form .blockfoot { padding-top: 0; }

/*******************************************************************************
 ** VB4 overrides */
.navbar li { font-size: 11px; }
.navbar .navbar {background: transparent; }
h1, h2, h3, h4, h5, h6{ font-weight: bold; }
td.thead { display: table-cell; width: auto; text-align: left; }
td.thead, th.thead, div.thead { padding: 4px; }
#whoposted td.username { width: 100%; }
#primary_content .popupbody li { padding: 0 !important; border: none; }
#primary_content .popupbody li.thead { padding: 4px !important; display: block; width: auto; font-size: 12px; text-align: left; }
#primary_content .popupbody li.tcat { padding: 5px 5px 4px !important; }
#primary_content .popupbody li > a, .popupbody li > label { padding: 6px; background-color: #ffffff; }
#primary_content .popupbody { background-color: #ffffff; }
#primary_content .popupbody li.tmenu > a:hover,
#primary_content .popupbody li.vbmenu_hilite > a { background-color: #CCCCCC; }
td.threadtitle { width: 100%; }
td.threadlastpost { width: 150px; }
.check_all_ctrl input { margin: 3px; }
.blockhead { width: inherit; margin-bottom: 0; }
h2.blockhead { padding: 5px 5px 4px; font-weight: bold; box-sizing: border-box; }
h4.blockhead { padding: 5px 0 4px; font-weight: bold; box-sizing: border-box; }
th.blocksubhead, a.blocksubhead { border-top: none; }
#imod_menu .thead { padding: 6px; }
#attachstats { margin-bottom: 1em; }
#attachstats h3 { margin: 0; background: #f5f5f5 !important; color: #333; }
.settings_form_border { padding: 10px; }
.attachmentrow { margin: 10px; border: 1px solid #ddd; background: #fcfcfc !important;  }
.checkall, .imod_checkbox { float: right; }
#calendarpicker .popupbody { border: 1px solid #DDDDDD; }

/*******************************************************************************
 ** VB4 overrides - (tsr.css file content) */

/* HEADER */
body .above_body {
	background: transparent;
	margin: 0;
}
body .logo-image {
	padding: 5px;
}
body .toplinks {
	border-radius: 0 0 5px 5px;
	padding: 0 0 5px 10px;
}
body .toplinks ul.isuser li a,
body .toplinks ul.isuser li a:hover,
body .toplinks ul.nouser li a,
body .toplinks ul.nouser li a:hover,
body .toplinks .logindetails,
body .toplinks .notifications a.popupctrl:hover,
body .toplinks .nonotifications a.popupctrl:hover,
body .toplinks .nonotifications a.popupctrl.active,
body .toplinks .notifications a.popupctrl.active { background-color: transparent; }
body .welcomelink { color: #fff !important; }
body .toplinks .logindetails {
	padding-top: 3px;
	height: auto;
}

/* NAVBAR */
body .navbar .navbar { background: transparent; }
body .navtabs { background: transparent; }
body .navtabs li,
body .navtabs li:first-child { border: 0; }
body .navtabs li a.navtab,
body .navtabs li.selected a.navtab {
	border: 1px solid #C4C4C4;
	border-bottom: 1px solid #006DA8;
	border-radius: 5px 5px 0 0;
	margin-right: 2px;
	padding: 0 10px 0;
	position: relative;
	top: -1px;
	z-index: 100;
	background: #fff;
	color: #333;
}
body .navtabs li.selected a.navtab {
	border-bottom: 1px solid #fff;
}
body .navtabs .floatcontainer {
	background: #fff;
	border: 1px solid #C4C4C4;
	border-bottom: 0;
	border-radius: 5px 5px 0 0;
	padding-top: 10px;
}
body .navtabs li.selected li a,
body .navbar_advanced_search li a {
	font-weight: bold;
}
body .navtabs ul { box-sizing: border-box; }
body .navtabs li.selected li a,
body .navtabs li.selected li a:hover,
body .navtabs li.selected li a.popupctrl { color: #006DA8; }

/* ??? */
body .body_wrapper {
	border: 1px solid #C4C4C4;
	border-top: 0;
	border-radius: 0 0 5px 5px;
	padding: 20px;
}
body .vb-tree-menu .ygtvitem {
	background: transparent;
}
body .blockhead .popupbody li > a {
	color: #333;
}
body .newcontent_textcontrol,
body .newcontent_textcontrol:hover {
	border-radius: 5px;
	background: #E97011;
	box-shadow: none;
	font-weight: bold;
}
body .notices li,
body .navlinks {
	box-shadow: none;
	border: 1px solid #c4c4c4;
}

/* WIDGETS */
body .cms_widget {
	box-shadow: none;
	border: 0;
}
body .cms_widget .block { padding: 0; }
body .cms_widget_header h3 { color: #fff; }
body .widget_content {
	border-top: 0;
	border-radius: 0 0 5px 5px;
	background: #F6F6F6;
	padding: 6px;
}

/* FORUM */
body .forumhead,
body .blockhead,
body .forum_info .blockhead,
body .wgo_block .blockhead,
body .threadlisthead,
body .postbit .posthead,
body .postbitlegacy .posthead,
body .eventbit .eventhead {
	border: 0;
}
body .forumbit_post .forumrow,
body .forumbit_nopost .forumhead,
body .forumbit_nopost .forumrow,
body .forumbit_post .forumhead,
body .forumbits .forumhead,
body .wgo_block,
body .threadlisthead,
body .threadbit,
body .forum_info .blockbody,
body .forum_info .blockhead {
	box-shadow: none;
}
body .forumrowdata,
body .wgo_block .blockbody,
body .formcontrols {
	border: 1px solid #C4C4C4;
	border-top: 0;
}
body .postbitlegacy .userinfo {
	float: none;
	width: 100%;
	position: relative;
}
body .postbitlegacy .postbody,
body .eventbit .eventdetails .eventbody {
	margin: 0;
	background: #f6f6f6;
}
body .postbitlegacy .userinfo {
	box-sizing: border-box;
	background: #e5e5e5;
}
body .postbit .postbody {
  background: none repeat scroll 0 0 transparent !important;
}
body .postbitlegacy .userinfo .userinfo_extra {
	position: absolute;
	right: 0;
	top: 0;
}
body .subforumlist { display: inline;}
body .subforumlistlabel { display: inline;}
body .last_post { min-width: 75px; }
body .no_posts p { margin-bottom: 1em; }

/*
#page_showthread #pagetitle h1 { font-size: 18px; color: #444; }
*/
#page_showthread .above_postlist, #page_showthread #above_postlist { height: auto; margin-bottom: 2px; }

.bbcode_container div.bbcode_code, .bbcode_container pre.bbcode_code {
    white-space: pre-wrap;
}

/* USER PROFILE */
body .memberprofiletabunder { display: none; }
body #usercp_nav .blockbody,
body .blockbody.settings_form_border { border: 1px solid #c4c4c4; border-top: none;}
body #sidebar_container.member_summary { box-shadow: none;}
body .custom_description { padding-bottom: 5px; margin-left: 2px; }
body .custom_fieldset { margin-left: 2px; }
body fieldset .inline { display: inline; }
body fieldset ul.checkradio { padding-top: 5px; }
body fieldset ul.checkradio li { display: inline; padding-left: 5px; }
body form#profileform fieldset.fieldset legend { font-size: 12px; }
body fieldset.fieldset .section label { display: inline; }

/* SEARCH */
body #searchtypeswitcher {
	border-bottom: 1px solid #c4c4c4;
}
body #searchtypeswitcher li.selected a {
	color: #fff;
}
body #searchtypeswitcher li a,
body #searchtypeswitcher li a:hover {
	background-color: #f6f6f6;
	color: #333;
	border: 1px solid #c4c4c4;
}

/* BLOG */
body #blog_user_sidebar,
body #blog_sidebar_generic {
	box-shadow: none;
}

/* General font overrides */
body .normal { font-size: 12px; }
.textbox, textarea, select { font-size: 12px; }

/*
 * ckeditor overrides
*/
.cke_skin_kama span .cke_button .cke_button_Subscript .cke_icon {
  background-position: -2147px 0 !important;
  background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/editor/sprite.png") !important;
}

.cke_skin_kama span .cke_button .cke_button_Superscript .cke_icon {
  background-position: -2197px 0 !important;
  background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/editor/sprite.png") !important;
}

#cke_contents_vB_Editor_QR_editor { min-height: 100px; }
.cke_editor tr td.cke_bottom { height: 21px; }

p.cb_disablesmilies { margin-left: 8px; }

/* Miscellaneous */
.ignorelist_hidden{
  display: none;
}
table#icon-legend td { padding: 1px; }
.noborder { border: none!important; }

#page_credits .panel div.fieldset { border: none; }
#page_credits .tborder .thead { text-align: center; }

/* Thread/Forum Moderation Overrides */
.toolsmenu {
  background: transparent !important;
  border: none !important;
  clear: none !important;
  float: right !important;
}
#above_threadlist_controls {
  width: 100%;
  margin-bottom: 10px;
}
.input-button a.popupctrl {
  color: #FFF;
}
#page_showthread #primary_content .popupbody li > a, #page_showthread .popupbody li > label { padding: 8px; background-color: #ffffff; }
#page_showthread .popupbody li .sprite_thread_icons { margin: 6px 3px 0 5px; float: left; }
#page_showthread .toolsmenu .popupgroup .popupbody, #page_moderation .toolsmenu .popupgroup .popupbody {
  padding: 1px;
}
#page_showthread .toolsmenu li, #page_moderation .toolsmenu li {
  clear: none;
}
#page_showthread .popupbody, #page_moderation .popupbody {
  border: 1px gray solid;
}
#page_showthread .popupbody li, #page_moderation .popup li {
  border: none;
}
#page_showthread .popupbody li > a, #page_showthread .popupbody li > label, #page_moderation .popupbody li > a, #page_moderation .popup li > label {
  background: #FFF;
}
#page_showthread .popupbody li.thead, #page_moderation .popupbody li.thead {
  padding: 5px 0 !important;
  width: 100%;
}
#page_showthread .menu_header, #page_moderation .menu_header {
  padding-left: 4px !important;
}

/* Search Tools*/
/*
#page_showthread .toolsmenu .popupgroup .popupbody#searchtools {
  background-color: #FFF;
}
#page_showthread .popupbody#searchtools li > a:hover {
  background-color: #FFF !important;
}
#page_showthread ul#searchtools li:first-child {
  background: #F6F6F6;
  font-size: 12px;
  color: #444;
}
#page_showthread ul#searchtools li {
  padding: 4px !important;
  float: none !important;
  width: auto !important;
}
*/
/* Thread Tools */
/*
#page_showthread .popupbody.thread_tools { left: 8px !important; top: 21px !important; }
#page_showthread .popupbody .moderation-radio li label{
  padding-left: 10px !important;
}
#page_showthread .popupbody .moderaton-radio li > a, #page_showthread .popupbody .moderation-radio li > label {
  padding: 4px;
}
#page_showthread .toolsmenu .popupgroup .popupbody a, #page_showthread .toolsmenu .popupgroup .popupbody li label {
  font-size: 12px;
}
#page_showthread .popupbody li > a:hover, #page_showthread .popupbody li > label:hover, #page_showthread .popupbody li.vbmenu_hilite > a, #page_moderation .popupbody li > label:hover, #page_moderation .popupbody li.vbmenu_hilite > a {
  background-color: #CCC;
}
#page_showthread .popupbody .moderation-radio li > a:hover, #page_showthread .popupbody li > label:hover, #page_showthread .popupbody .moderation-radio li.vbmenu_hilite > a {
  background-color: #FFF;
}
*/
/* Thread Admin - Delete threads */
#page_inlinemod .vbform fieldset ul.checkradio { padding: 10px 0 0 10px; }
#page_inlinemod .vbform .keepattachments { display: inline-block; margin: 5px 0 10px; }
#page_inlinemod .vbform li:last-child { margin-top: 10px; }
#page_inlinemod .vbform .deletereason { float: none; display: block; margin-top: 15px; }
#page_inlinemod .vbform input.textbox { height: 18px; padding: 0; }

/* Thread Admin - Delete thread as spam */
#page_inlinemod .vbform .shade { color: #444; margin-bottom: 10px; }
#page_inlinemod .vbform .spamoptions { margin-top: 0; }

/* Thread Admin - Merge thread */
#page_inlinemod .mergethreads fieldset.smallfont div.fieldset { border: none; margin-bottom: 0; padding: 2px 0 7px; }

/* Thread Admin - Change Thread/Post Owner */
#page_inlinemod .modify_date .bginput { background: #ebebeb !important; color: #bbb; }
#page_inlinemod .modify_date .bginput.modify { background: #fff !important; color: #000; }

/* Thread Post Post-List */
/* .postlistfoot { clear: none !important; float: right; } */
#below_postlist { margin-top: 0; }
.postbitlegacy.imod_highlight .postbody, .postbitlegacy.imod_highlight .postbody .quote_block, .postbit-lite.imod_highlight .postbody { background: #FFFFE3 !important; }

/*
#page_showthread .postbitlegacy .postrow, #page_showthread .eventbit .eventrow, #page_showthread .postbitdeleted .postrow, #page_showthread .postbitignored .postrow {
  padding: 0;
  color: #444;
}

#page_showthread .postbitlegacy img.onlinestatus, #page_showthread .eventbit .eventrow img.onlinestatus, #page_showthread .postbitdeleted img.onlinestatus, #page_showthread .postbitignored img.onlinestatus {
  position: static;
}

#page_showthread .postbitlegacy input.postimod, #page_showthread .eventbit input.postimod, #page_showthread .postbitdeleted input.postimod, #page_showthread .postbitignored input.postimod {
  vertical-align: middle;
  margin: 0 0 0 5px;
}

#page_showthread .postbitlegacy table.posttable, #page_showthread .eventbit table.posttable, #page_showthread .postbitdeleted table.posttable, #page_showthread .postbitignore table.posttable {
  margin-bottom: 12px;
}
*/
/* Picture Messages */
.postbit-lite .avatarlink img { height: 45px !important; width: auto !important; margin-right: 25px;}
#message_list { padding: 10px; }
#message_list .postbit { background: inherit; }
#message_list .postbithead { margin-left: 0; background: inherit; border: none; border-top: 1px dashed #ddd; border-bottom: 1px dashed #ddd; height: 45px;}
#message_list .posttext { margin-left: 0; background: white; }
#message_list .postbit-chkbox { right:0; }

/* Visitor Messages */
.visitor_message { max-width: none; }
.visitor_message .actionbuttons .group { text-align: center; }
.visitor_message .blockhead { margin-top:0; }
.visitor_message .noborder { border: none; }
.visitor_message .description { margin-left: 15px!important;  }
.visitor_message .formcontrols { margin-bottom: 2em; }
.visitor_message .blockrow .group li label { width: auto; }

#page_visitormessage #cke_vB_Editor_001_editor { width: 519px; }

/* 404 page */
#page_404 .column.main ul,
#page_login .panelsurround ul { padding-left: 40px; display: block; list-style-type: disc; margin: 1em 0; }
#page_404 .column.main ul li[type=disc],
#page_login .panelsurround li{ list-style: disc; }

/* Registration Page */
.error_list { margin: 15px 0 15px 40px; }
.error_list li { list-style-type: disc; }

/* Messagearea */
.messagearea-message { margin-bottom: 5px; }
#messagearea .blockrow,
#message_form .blockrow { padding: 0; }
#message_form .blockrow { margin-bottom: 10px; }
#messagearea  { margin-bottom: 10px; }
body .editor_textbox_smilie span.cke_skin_kama { border: none; }
#newpost_options fieldset { margin-bottom: 12px; margin-left: 0; margin-right: 0; }
#newpost_options .description { padding-left: 0; }
#attachfiles legend, #aftersubmit legend { font-size: 12px; }
#aftersubmit ul.rightcol { margin-left: 0;}
#aftersubmit ul li { display: block; }

/* Edit Attachments */
.thumbnail_link { float: left; padding-right: 20px; }
.attachment_title { font-size: 11px; }
.attachment_title a { font-weight: normal; }
#attachstats .blockbody { font-size: 12px; }
#attachmentlist .blockfoot { padding: 8px 0; }
#attachfiles .attachments { background: none; border: none; padding: 0; margin: 5px 0; }

  /*vertical align class*/
.valign_parent { height: 100%; width: 100%; display: table; padding-bottom: 10px;}
.valign_top { display: table-cell; width: 100%; vertical-align: top; }
.valign_center { display: table-cell; width: 100%; vertical-align: middle; }

/* Coursework */
.coursework p { margin-bottom: 1em; }

/* Modify Signature */
table#modify-signature .smallfont { margin-left: 10px; }

/* Private Messages */
#pmrecips_txt, #bccpmrecips_txt { padding: 2px; border-color: #AAAAAA; }
#edit .inlineimg { vertical-align: middle; }
#page_private .floatcontainer:after { padding-bottom: 0; }
#page_private #threadpms .block > * { border: 1px solid #DDD; border-top: none; border-radius: 0 0 5px 5px; padding: 6px; font-size: 12px; text-align: center; }
#page_private #pm_delete .smallfont { font-size: 11px; }
.trackpm_pagenav { padding-top: 3px; padding-bottom: 8px; }
.lostpw .group { text-align: center; }
#nav_pmfolders_menu table, #postmenu__menu table { border-spacing: 1px; }
#usercp_pmfilter tr td div div.floatcontainer div{ display: inline; padding-bottom: 15px; }
.usercp_pmfullbar {height: 1px; }
#page_private #pm_quickreply textarea { white-space: pre-wrap; }

/* Announcement Pages */
#announcementlist .inlineimg { vertical-align: middle; }
#page_announcement #pagetitle { float: left; }
#page_announcement #pagetitle h1 { display: none; }
#page_announcement a.blocksubhead_info { border: none; background: none; }
#page_announcement label { width: auto; }


/* Birthday fields - register & edit profile pages */
#birthday_table td { padding-right: 2px; }
#birthday_table tr.smallfont-bold td { padding-top: 2px; padding-right: 2px; }

/* sendpage Page */
#page_sendmessage #threadtitle { float: right; font-size: 11px; }
#page_sendmessage #threadtitle a { color: #ffffff; font-weight: normal; }
#page_sendmessage .wysiwyg_block .blockbody { border-radius: 0 0 5px 5px; padding: 10px; }
#page_sendmessage .wysiwyg_block .blockrow label { float: none; }
#page_sendmessage .wysiwyg_block .formcontrols .blockrow:after { padding-bottom: 0; }
#page_sendmessage .wysiwyg_block .formcontrols .primary { width: 65%; }
#page_sendmessage .wysiwyg_block .formcontrols textarea.primary { width: 99%; height: 150px; }
#page_sendmessage .wysiwyg_block .formcontrols .actionbuttons .group { text-align: center; }

/* FAQ */
.faq li { list-style: inherit; }
.faq ul, .faq ol { padding-left: 40px;  margin: 10px 0;}
.faq p { padding-bottom: 10px; }
.faq .highlight { background: transparent; }

/* Wiki */
#page_wiki li { list-style-type: disc; }
#page_wiki .inlinelist li { list-style: none; }
#page_wiki .ui-helper-reset li { list-style: none; }
#page_wiki #wiki_content #toc ul li, #page_content #wiki_content .toc ul li{ list-style: none; }
#page_wiki table { border-spacing: 0; }
#page_wiki select { height: auto; margin-left: 4px; }
#page_wiki input { margin-left: 4px; }
#page_wiki .widget_column li { list-style-type: none; }


/* Inline Mod Page */
#page_inlinemod .blockrow.texteditor { padding: 5px 3px 5px 0; }
#page_inlinemod fieldset.fieldset .fieldset { border: none; padding: 0; }
#page_inlinemod fieldset.fieldset input[type=radio] { margin-top: 7px; }
#page_inlinemod fieldset.fieldset input[type=radio], #page_inlinemod fieldset.fieldset input[type=checkbox] { margin-right: 3px; margin-left: 3px;}
#page_inlinemod ul.spamoptions { margin-top: 10px; }
#page_inlinemod .rightcell .fieldset, #page_inlinemod .leftcell .fieldset { padding: 5px; }
#page_inlinemod #cke_vB_Editor_001_editor { width: 526px; }

/* Who's online */
#page_online #above_who_online { margin-bottom: 3px; }
#page_online .blockhead { font-size: 11px; }
#page_online .blockhead a { float: right; color: #fff; }
#page_online .blockhead p { color: #fff; font-weight: normal; margin-top: 5px; padding: 0; }
#page_online .blockhead p span { color: #fff; }
#page_online .formcontrols { border: none; }
#page_online #woltable { border-spacing: 0; }
#page_online #woltable thead td { font-size: 12px; }
#page_online #woltable thead td:first-child,
#page_online #woltable thead td:last-child { border-radius: 0; }
#page_online #who_online_block_1, #page_online #who_online_block_2 { float: none; }
#page_online #who_online_block_2 { background: #f6f6f6; width: 100%; margin-top: 15px; }
#page_online .floatcontainer:after { padding-bottom: 0; }
#page_online #who_online_block_2 .formcontrols { background: none !important; border: none; text-align: center; }
#page_online #who_online_block_2 .textbox { height: 18px; padding: 0; }
#page_online #who_online_block_2 select, #page_online #who_online_block_2 .textbox { margin-right: 20px; }
#page_online #pagination_bottom { margin-top: 0; }
#page_online #who_online_block_1 .blockbody,
#page_online #who_online_block_1 .blockrow { background: none !important; }

/* Moderation Templates */
#page_moderation em { font-style: italic; }

#page_moderation #display_options .blockhead { padding: 6px; }

#page_moderation #message_list ul.controls { text-align: right; }
#page_moderation #message_list ul.controls li { display: inline; margin-left: 6px; }

#page_moderation #message_list div.info_bar input.inlinemod_checkbox { float: right; }
#page_moderation #message_list td.picture_thumbnail { padding-right: 6px; width: 150px; text-align: center; }
#page_moderation #message_list div.info_bar { border-width: 1px 0 0 0; margin-bottom: 6px; border: 1px solid #DDD !important; }
#page_moderation #message_list span#datetime { float: right; }

#page_moderation #message_list div.message_date { float: right; }

#page_moderation #message_list div.fixed_width_avatar { float: left; }
#page_moderation #message_list div.fixed_width_avatar { text-align: center; width: 60px; margin-right: 6px; float: left; }
#page_moderation #message_list div.visitor_message_avatar_margin { margin-left: 75px; }
#page_moderation #message_list div.visitor_message_date { float: right; }

#page_moderation li.postcontainer { padding: 6px 0 6px 0; }
#page_moderation li.postcontainer td.postbody.inlinemod { background: #FFFFE3 !important; }

#page_moderation div#attachmentlist { background: #F6F6F6; padding: 1px; border-radius: 0 0 5px 5px; }
#page_moderation div#inlinemod_formctrls { float: left; }
#page_moderation ol#posts li.attachmentrow { background: #F6F6F6 !important; }
#page_moderation ol#posts li.attachmentrow.imod_highlight { background: #FFFFE3 !important; }

#page_moderation .popupmenu h6 a { background-position: center center; }
#page_moderation .popupmenu .popupbody { padding: 1px; }

/* Post-Edit History Page */
#page_showpostedithistory li.add div, #page_showpostedithistory li.add blockquote, #page_showpostedithistory li.remove div, #page_showpostedithistory li.remove blockquote { display: inline; }
#page_showpostedithistory li.add { background: #ddffdd; color: #00bb00; }
#page_showpostedithistory li.remove { background: #ffdddd; color: #bb0000; }

/* Post Preview */
#post_preview ul, #post_preview ol { margin-left: 15px; }
#post_preview ol li { list-style-type: decimal; }
#post_preview ul li { list-style: disc; }
#post_preview sup { vertical-align: top; }
#post_preview sub { vertical-align: sub; }
#post_preview table td { border: 1px solid #000; padding: 0.5em; }

#vB_Editor_QR_dialog #uploadbutton {
    margin-top:3px;
    padding:2px 3px;
    border: 1px solid #666666;
    cursor:pointer;
    background-color:#EEEEEE;
    border-radius: 3px 3px 3px 3px;
}

#fb_loginbtn {
    padding:0px !important;
    margin-top:3px;
}

#fb_link {
    margin-right:5px;
}
